/* @flow */
/**
 * Component for rendering a fullscreen background
 * image with text centered vertically on top. Title and p
 * can be passed as attributes, for other components use children.
 */
/* eslint-env browser */

import type { Node } from 'react';

import { Component } from 'react';
import classnames from 'classnames';

import T from '../general/translate.jsx';
import Link from '../general/link/';
import FullHeight from '../general/fullHeight/';
import PhotoFullWidth from '../general/photoFullWidth/';
import PhotoCreditLink from '../general/photoCreditLink.jsx';

type Props = {
  // minHeight in percentage of screen, if not set and not disabled, this defaults to 100%
  minHeight?: number,
  className?: string,

  // url for background image
  background?: string,

  // eg "bottom center"
  backgroundPosition?: string | boolean,

  // add an additional class to content
  // (usually to push or pull the content down/up)
  contentClass?: string,

  // is this the top slide? (then we add negative
  // margin to move it under the navbar)
  topSlide?: boolean,

  // icon path to show up above the headline
  icon?: string,

  // headline translation key
  headline?: string,
  // used to make the headline an h1 component (SEO relevant)
  headlineComponent?: string,
  headlineProps?: Object,
  headlineClass?: string,

  // text translation key
  text?: string,
  additionalTextClass?: string,

  // shows up underneath the children
  subline?: string | boolean,

  srcSet?: boolean,

  // username to credit photo to
  credit?: string,

  // additional classname for the credit section
  // (e.g. to hide it on certain breakpoints)
  creditClassName?: string,

  // ID might be needed for scrollTo functionality
  id?: string,

  // content displayed in the bottom right
  bottomRight?: Object,

  // content displayed centered at bottom
  bottomCenter?: Object,

  // half transparent box at bottom of imagebreak
  bottomBox?: Object,

  // different bottom box height if subline is missing
  bottomBoxNoSubline?: boolean,

  // should stuff be translated?
  noTranslate?: boolean,

  // disable the fullheight component which blows
  // up the imageBreak to full browser height
  disableFullHeight?: boolean,

  // white background, black copy
  lightTheme?: boolean,

  children?: Node,

  // overwrite for default content grid classes
  gridClasses?: string,

  noMaxWidth?: boolean,

  leftAligned?: boolean,

  // if true it activates the fadein effect for all center content
  fadeInEffect?: boolean,

  prevLink?: string,
  prevLinkOnClick?: Function,
  nextLink?: string,
  nextLinkOnClick?: Function,

  // fake way to push content to the bottom of the component
  contentToBottom?: boolean,

  hasGradient?: boolean,
};

type State = {
  transition: boolean,
};

class ImageBreak extends Component<Props, State> {
  timer: number;

  state = {
    transition: false,
  };

  componentDidMount() {
    if (this.props.fadeInEffect) {
      this.timer = setTimeout(this.triggerTransition, 100);
    }
  }

  componentWillUnmount() {
    if (this.props.fadeInEffect) {
      window.clearInterval(this.timer);
    }
  }

  triggerTransition = () => {
    this.setState({ transition: true });
  };

  render() {
    return (
      <FullHeight
        disable={this.props.disableFullHeight}
        className={classnames('imageBreak', this.props.className)}
        topSlide={this.props.topSlide}
        minHeight={this.props.minHeight}
        id={this.props.id}>
        <PhotoFullWidth
          background
          hasGradient={this.props.hasGradient}
          contentToBottom={this.props.contentToBottom}
          component="section"
          url={this.props.background}
          backgroundPosition={this.props.backgroundPosition}
          srcSet={this.props.srcSet}
          className={classnames({
            'photo-fullWidth-whiteBackground': this.props.lightTheme,
          })}>
          <div
            className={classnames(
              'imageBreak_text',
              { 'lp_maxWidth row': !this.props.noMaxWidth },
              this.props.contentClass
            )}>
            <div
              className={classnames(this.props.gridClasses, {
                'text-centered': !this.props.leftAligned,
                'typo-topMarginBoost':
                  this.props.disableFullHeight && this.props.topSlide,
                'column small-12 medium-10 medium-offset-1':
                  !this.props.gridClasses,
                'headline-appear': this.props.fadeInEffect,
                'headline-appear-active':
                  this.props.fadeInEffect && this.state.transition,
              })}>
              {this.props.icon && (
                <img alt="" src={this.props.icon} className="typo_img" />
              )}
              {this.props.headline && (
                <T
                  {...this.props.headlineProps}
                  noTranslate={this.props.noTranslate}
                  className={classnames(this.props.headlineClass, {
                    typo_headline2: !this.props.headlineClass,
                    'typo-color-white': !this.props.lightTheme,
                  })}
                  component={this.props.headlineComponent}>
                  {this.props.headline}
                </T>
              )}
              {this.props.text && (
                <T
                  noTranslate={this.props.noTranslate}
                  className={classnames(
                    'typo_p columns small-centered small-10 medium-8 large-8 xlarge-8',
                    this.props.additionalTextClass,
                    {
                      'typo-color-grey3': this.props.lightTheme,
                      'typo-color-white': !this.props.lightTheme,
                    }
                  )}
                  component="p">
                  {this.props.text}
                </T>
              )}
              {this.props.children && (
                <div className="typo_wrapper">{this.props.children}</div>
              )}
              {this.props.subline && (
                <T
                  noTranslate={this.props.noTranslate}
                  className={classnames(
                    'typo_p typo_p-topMargin columns small-centered',
                    'small-10 medium-8 large-8 xlarge-8',
                    {
                      'typo-color-grey3': this.props.lightTheme,
                      'typo-color-white': !this.props.lightTheme,
                    }
                  )}
                  component="p">
                  {this.props.subline}
                </T>
              )}
              {this.props.bottomCenter && (
                <div className="hide-for-medium-up">
                  {this.props.bottomCenter}
                </div>
              )}
              {this.props.bottomRight && (
                <div className="hide-for-medium-up typo-topMarginBoost-small">
                  {this.props.bottomRight}
                </div>
              )}
            </div>
          </div>

          {this.props.credit && (
            <PhotoCreditLink
              className={this.props.creditClassName}
              credit={this.props.credit}
            />
          )}
          {this.props.bottomLeft && (
            <div className="imageBreak_bottomLeft">{this.props.bottomLeft}</div>
          )}
          {this.props.bottomCenter && (
            <div className="imageBreak_bottomCenter">
              {this.props.bottomCenter}
            </div>
          )}
          {this.props.bottomRight && (
            <div className="imageBreak_bottomRight">
              {this.props.bottomRight}
            </div>
          )}
          {this.props.bottomBox && (
            <div
              className={classnames('landing_sections_bottomBox', {
                'landing_sections_bottomBox-noSubline':
                  this.props.bottomBoxNoSubline,
              })}>
              <div className="lp_maxWidth">{this.props.bottomBox}</div>
            </div>
          )}
        </PhotoFullWidth>
        {this.props.prevLink && (
          <Link
            href={this.props.prevLink}
            onClick={this.props.prevLinkOnClick}
            className="pview_stage_prev"
          />
        )}
        {this.props.nextLink && (
          <Link
            href={this.props.nextLink}
            onClick={this.props.nextLinkOnClick}
            className="pview_stage_next"
          />
        )}
      </FullHeight>
    );
  }
}

ImageBreak.defaultProps = {
  headlineComponent: 'h2',
};

export default ImageBreak;
