/* @flow */
import { connect } from 'react-redux';

import { getIsDesktop } from '../../../reducers/';

import FullHeight from './fullHeight.jsx';

const mapStateToProps = (state) => ({
  isDesktop: getIsDesktop(state),
});

export default connect(mapStateToProps)(FullHeight);
