// @flow
import { gql } from '@apollo/client';

export const CONTACT = gql`
  mutation contact($payload: ContactInput!) {
    contact(payload: $payload) {
      statusCode
    }
  }
`;
