/* @flow */
import { connect } from 'react-redux';

import {
  getUTM,
  getAuthUser,
  getIsMobile,
  getIsBuyer,
  getCountryIso,
} from '../../../reducers/';

import ContactForm from './contactForm.jsx';

export default connect((state: RootReducerState) => ({
  countryIso: getCountryIso(state),
  utm: getUTM(state),
  authUser: getAuthUser(state),
  isMobile: getIsMobile(state),
  isBuyer: getIsBuyer(state),
}))(ContactForm);
