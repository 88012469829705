import { connect } from 'react-redux';
import { getDevice, getMaxDeviceWidth } from '../../../reducers/';

import PhotoFullWidth from './photoFullWidth.jsx';
import { getBreakpointName, getDevicePixelRatio } from '../../../reducers/';
import { getSrc } from '../../../helpers/photoSrc';

const mapStateToProps = (state, ownProps) => {
  const devicePixelRatio = ownProps.forceRetina
    ? 2
    : getDevicePixelRatio(state);

  const breakpointName = getBreakpointName(state);
  const maxDeviceWidth = getMaxDeviceWidth(state);

  return {
    url: ownProps.url,
    operatingSystem: getDevice(state).operatingSystem,
    maxDeviceWidth,
    src: getSrc({
      url: ownProps.url,
      srcSet: ownProps.srcSet,
      breakpointName,
      devicePixelRatio,
      size1: 'w',
      size2: maxDeviceWidth,
    }),
  };
};

export default connect(mapStateToProps)(PhotoFullWidth);
