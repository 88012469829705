/* @flow */
import classnames from 'classnames';
import t from 'counterpart';

import Link from './link/';

function PhotoCredit(props: {
  // username to credit
  credit: string,

  // additional classname for the credit
  className?: string,
}) {
  return (
    <Link
      className={classnames('imageBreak_credit', props.className)}
      href={`/u/${props.credit.toLowerCase()}`}
      title={`@${props.credit}`}>
      {t('photoCredit.photoBy')}
      <span> @{props.credit}</span>
    </Link>
  );
}

export default PhotoCredit;
