/* @flow */
/**
 * Render children in a div that resizes to the window size so that it fills the height.
 * If the content is larger than the current window size the content will stretch the <FullHeight/>.
 *
 * Resizing is done in CSS. <FullHeight>s can take other elements into account.
 *
 * Full height can be switched off for devices types too.
 */

import type { Node } from 'react';
import classnames from 'classnames';

function FullHeight(props: {
  // minHeight in percentage of screen, if not set and not disabled, this defaults to 100%
  minHeight: number,
  className: string,
  // id for scrollTo
  id: string,
  // any background color other than #ffffff
  backgroundColor: string,
  // is this the topSlide? In that case we add negative margin to move it under the navbar
  topSlide: boolean,
  // Disable the whole shebang
  disable: boolean,
  children: Node | Array<Node>,
}) {
  const style = {};

  // we use CSS viewport units before JS loads and we default to 100%
  if (!props.disable) {
    style.minHeight = (props.minHeight && `${props.minHeight}vh`) || '100vh';
  }

  return (
    <div
      className={classnames(props.className, {
        fullHeight: !props.disable,

        disabledFullHeight: props.disable,

        moveBehindNavbar: props.topSlide,
      })}
      style={style}
      id={props.id}>
      <div
        className="fullHeight_content"
        style={{
          backgroundColor: props.backgroundColor || '#ffffff',
          minHeight: style.minHeight,
        }}>
        {props.children}
      </div>
    </div>
  );
}

export default FullHeight;
