/** @flow */
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import qs from 'qs';
import t from 'counterpart';

import Form from './form.jsx';

import { track, isOneOf, cleanObject } from '../../../helpers/tools';
import { CONTACT } from '../../../graphql/mutations/contact';

type Props = {
  submitContactForm: (payload: SubmitContactFormPayload) => void,
  contentClassName: string,
  buttonBoxClassName: string,
  authUser: AuthUser,
  utm: {},
  onSubmit: () => void,
  onSuccess: () => void,
  formVersion: 'long' | 'short' | 'cart' | 'jobs' | 'support' | 'supportPayout',
  recipient: string,
  showMessageWhenSent: boolean,
  isMobile: boolean,
  isBuyer: boolean,
  countryIso: string,
  attachedData?: mixed, // any data that should be forwarded
};

const ContactForm = (props: Props) => {
  const [submitContactForm, { data }] = useMutation(CONTACT);
  const [submitPending, setSubmitPending] = useState(false);
  const [sent, setSent] = useState(false);

  useEffect(() => {
    if (data?.contact?.statusCode == 200) {
      props.onSuccess();
    }
  }, [data?.contact]);

  const submit = (values: { email: string, message: string } & {}) => {
    if (props.onSubmit) {
      props.onSubmit();
    }

    setSubmitPending(true);
    setSent(true);

    const queryString = qs.parse(window.location.search.substr(1));
    const eventPosition =
      queryString.eventPosition || t('modal.contact.eventPosition');

    track({
      ...props.utm,
      eventType: t('tracking.eventType.inbound'),
      eventName: t('modal.contact.submit.eventName'),
      eventAction: `click_button_${props.formVersion}`,
      eventLabel: t('modal.contact.submit.label'),
      eventPosition,
    });

    const { email, message, ...other } = values;

    return submitContactForm({
      variables: {
        payload: {
          email,
          recipient: queryString.recipient || props.recipient,
          message: message || t('modal.contact.noMessage'),
          requestType: t(`modal.contact.${props.formVersion}.subject`),
          additionalFields: {
            ...other,
            ...props.attachedData,
            eventPosition,
            formVersion: props.formVersion,
            page: window.location.pathname,
          },
        },
      },
    });
  };

  const formVersionMatches = (formVersions: $ReadOnlyArray<string>) =>
    isOneOf(props.formVersion, formVersions);

  const visibleElements = () => ({
    name: formVersionMatches([
      'long',
      'short',
      'jobs',
      'support',
      'cart',
      'supportPayout',
    ]),
    companyName: formVersionMatches(['long', 'short', 'cart']),
    message: formVersionMatches(['jobs', 'support', 'supportPayout']),
    bottomLine: formVersionMatches(['long']),
    position: formVersionMatches(['long']),
    usage: formVersionMatches(['long']),
    address: formVersionMatches(['cart']),
    companySize: formVersionMatches(['long']),
    phoneNumber: props.isBuyer,
  });

  return (
    <Form
      contentClassName={props.contentClassName}
      buttonBoxClassName={props.buttonBoxClassName}
      formVersion={props.formVersion}
      showMessageWhenSent={props.showMessageWhenSent}
      isMobile={props.isMobile}
      isBuyer={props.isBuyer}
      visibleElements={visibleElements()}
      initialValues={cleanObject({
        email: (props.authUser && props.authUser.email) || '',
        position: visibleElements().position ? '__selectTitle__' : undefined,
        usage: visibleElements().usage ? '__selectTitle__' : undefined,
        companySize: visibleElements().companySize
          ? '__selectTitle__'
          : undefined,
        country: props.countryIso,
      })}
      countryIso={props.countryIso}
      sent={sent}
      submitPending={submitPending}
      attachedData={props.attachedData}
      onSubmit={submit}
    />
  );
};

export default ContactForm;
