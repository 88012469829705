/* @flow */
/* eslint-env browser */
import { Text, Box } from '@eyeem-ui/atoms';
import { Close } from '@eyeem-ui/icons';
import { ThemeProvider } from '@eyeem-ui/theme';

import t from 'counterpart';
import ContactForm from '../../general/contactForm/';

import { TEST_MODAL_CLOSE } from '../../../constants/pageObjectSelectors';

import LegacySuccessModal from '../legacySuccessModal.jsx';
import { useState } from 'react';

const getErrorMessage = (serverMessage, statusCode) => {
  if (
    statusCode &&
    !t(`modal.contact.statusCodeMessage.${statusCode}.subline`).includes(
      'missing translation'
    ) &&
    !t(`modal.contact.statusCodeMessage.${statusCode}.subline`).includes(
      'missing translate'
    )
  ) {
    return t(`modal.contact.statusCodeMessage.${statusCode}.subline`);
  }
  return serverMessage;
};

const getErrorTitle = (title, formVersion, statusCode) => {
  if (
    statusCode &&
    !t(`modal.contact.statusCodeMessage.${statusCode}.headline`).includes(
      'missing translation'
    ) &&
    !t(`modal.contact.statusCodeMessage.${statusCode}.headline`).includes(
      'missing translate'
    )
  ) {
    return `modal.contact.statusCodeMessage.${statusCode}.headline`;
  }
  if (statusCode === 'UNKNOWN_DIRECTDOWNLOAD_ERROR') {
    return `modal.contact.directDownloadError.title`;
  }
  return title || `modal.contact.${formVersion}.title`;
};

function ContactModal(props: {
  className?: string,
  close: CloseModal,
  onSubmit?: Function,
  formVersion: string,
  recipient: string,
  attachedData?: mixed, // any data that should be forwarded
  title?: string,
  subline?: string,
  serverMessage?: string,
  statusCode: string,
  success: boolean,
}) {
  const [success, setSuccess] = useState(false);

  if (success) {
    return (
      <LegacySuccessModal
        {...props}
        translationKeys={{
          message: `modal.contact.${props.formVersion}.confirmationMessage`,
          close: 'modal.success.default',
        }}
      />
    );
  }

  return (
    <div className={props.className}>
      <div className="modal_content modal_content-noBottom">
        <ThemeProvider mode="light">
          <button
            type="button"
            data-test-id={TEST_MODAL_CLOSE}
            className="dont-be-a-button modal_close"
            onClick={props.close}>
            <Close />
          </button>
          <Box mt={3}>
            <Text variant="title4">
              {t(
                getErrorTitle(props.title, props.formVersion, props.statusCode)
              )}
            </Text>
          </Box>

          {props.subline && <Text>{t(props.subline)}</Text>}
          {props.serverMessage && (
            <Box mt={2}>
              <Text color="ghost70">
                {getErrorMessage(props.serverMessage, props.statusCode)}
              </Text>
            </Box>
          )}
        </ThemeProvider>
      </div>
      <ContactForm
        contentClassName="modal_content modal_content-noTop typo-topMarginBoost-smallStatic"
        buttonBoxClassName="modal_buttonBox modal_buttonBox-seamless"
        onSubmit={props.onSubmit}
        onSuccess={() => setSuccess(true)}
        recipient={props.recipient}
        attachedData={props.attachedData}
        formVersion={props.formVersion}
      />
    </div>
  );
}

ContactModal.defaultProps = {
  attachedData: {},
  onSubmit: undefined,
  className: undefined,
  title: undefined,
  subline: undefined,
  serverMessage: undefined,
};

export default ContactModal;
