/* @flow */
import classnames from 'classnames';

import Photo from '../photo/';

type Props = {
  maxDeviceWidth: number,
  className: string,

  // where do we find the image?
  url: string,
  src: string,
  operatingSystem: string,

  // the following props are consumed by <Photo

  // should it be a background image?
  background: boolean,
  component: string,

  backgroundPosition: boolean | string,

  // fake way to push content to the bottom of the component
  contentToBottom: boolean,

  contentSpacingMinimal: boolean,
  hasGradient?: boolean,

  alt?: string,
};

function PhotoFullWidth(props: Props) {
  const size2 = props.src?.split('/').pop();
  return (
    <>
      <Photo
        ignoreCache
        {...props}
        size1="w"
        size2={size2}
        className={classnames('photo-fullWidth', props.className, {
          'photo-hasGradient': props.hasGradient,
          'fullHeight_contentSpacing-minimal': props.contentSpacingMinimal,
          'fullHeight_contentSpacing-centered':
            !props.contentToBottom && !props.contentSpacingMinimal,
          'fullHeight_contentSpacing-bottom':
            props.contentToBottom && !props.contentSpacingMinimal,
        })}
      />
      <img src={props.src} style={{ display: 'none' }} alt={props.alt} />
    </>
  );
}

export default PhotoFullWidth;
