/* @flow */
/* eslint-env browser */
import t from 'counterpart';
import { ThemeProvider } from '@eyeem-ui/theme';
import { Text, Box, Flex, Button } from '@eyeem-ui/atoms';
import { Support } from '@eyeem-ui/icons';
import { Field, reduxForm } from 'redux-form';
import type { FormProps } from 'redux-form';

import { Input, Select, Textarea } from '../../formElements/';
import ImageBreak from '../../landing/imageBreak.jsx';
import T from '../translate.jsx';

import { getPhoneNumberByCountryIso } from '../../../helpers/tools';

import { COUNTRIES } from '../../../constants/misc';

import validate, {
  VALIDATOR_UNICODE_STRING,
  VALIDATOR_REQUIRED,
  VALIDATOR_EMAIL,
  VALIDATOR_ZIP,
} from '../../../helpers/validators';

const required = validate(VALIDATOR_REQUIRED);
const unicodeString = validate(VALIDATOR_UNICODE_STRING);
const email = validate(VALIDATOR_EMAIL);
const zip = validate(VALIDATOR_ZIP);

type Props = {
  contentClassName: string,
  buttonBoxClassName: string,
  formVersion: 'long' | 'short' | 'cart' | 'jobs' | 'support' | 'supportPayout',
  showMessageWhenSent: boolean,
  isMobile: boolean,
  isBuyer: boolean,
  countryIso: string,
  sent: boolean,
  submitPending: boolean,
  visibleElements: {
    name: boolean,
    companyName: boolean,
    message: boolean,
    bottomLine: boolean,
    position: boolean,
    usage: boolean,
    companySize: boolean,
    phoneNumber: boolean,
    address: boolean,
  },
  // any data that should be forwarded
  attachedData?: mixed,
} & FormProps;

function ContactFormForm(props: Props) {
  if (props.showMessageWhenSent && props.sent) {
    return (
      <ImageBreak
        lightTheme
        topSlide={!props.isMobile}
        minHeight={100}
        icon="/node-static/img/success.svg"
        headline={`modal.contact.${props.formVersion}.confirmationMessage`}
        headlineClass="modal_h2"
      />
    );
  }

  return (
    <ThemeProvider mode="light">
      <form onSubmit={props.handleSubmit}>
        <div className={props.contentClassName}>
          {props.visibleElements.name && (
            <Field
              component={Input}
              placeholder={t(
                `modal.contact.${props.formVersion}.placeholderName`
              )}
              name="name"
              type="text"
              validate={[required, unicodeString]}
              autoComplete="name"
            />
          )}
          {props.visibleElements.companyName && (
            <Field
              component={Input}
              placeholder={t(
                `modal.contact.${props.formVersion}.placeholderCompanyName`
              )}
              name="companyName"
              type="text"
              validate={[required, unicodeString]}
              autoComplete="company"
            />
          )}
          <Field
            component={Input}
            placeholder={t(
              `modal.contact.${props.formVersion}.placeholderEmail`
            )}
            name="email"
            type="email"
            validate={[required, email]}
            autoComplete="email"
          />
          {props.visibleElements.address && [
            <Field
              key="street"
              component={Input}
              placeholder={t(
                `modal.contact.${props.formVersion}.placeholderStreet`
              )}
              name="street"
              validate={[required, unicodeString]}
              autoComplete="street-address"
            />,
            <Flex key="zip">
              <Field
                component={Input}
                placeholder={t(
                  `modal.contact.${props.formVersion}.placeholderZip`
                )}
                name="zip"
                validate={[required, zip]}
                autoComplete="postal-code"
              />
              <Field
                component={Input}
                placeholder={t(
                  `modal.contact.${props.formVersion}.placeholderCity`
                )}
                name="city"
                validate={[required, zip]}
                autoComplete="address-level2"
              />
            </Flex>,
            <Field
              key="country"
              component={Select}
              name="country"
              text={t(`modal.contact.${props.formVersion}.placeholderCountry`)}
              options={COUNTRIES.map((value) => ({
                value,
                label: t(`countries.${value}`),
              }))}
              validate={required}
            />,
          ]}
          {props.visibleElements.position && (
            <Field
              component={Select}
              name="position"
              text={t('modal.contact.selectValues.position.__selectTitle__')}
              options={[
                'inHouse',
                'agency',
                'artBuyer',
                'photographer',
                'other',
              ].map((value) => ({
                value,
                label: t(`modal.contact.selectValues.position.${value}`),
              }))}
              validate={required}
            />
          )}
          {props.visibleElements.usage && (
            <Field
              component={Select}
              name="usage"
              text={t('modal.contact.selectValues.usage.__selectTitle__')}
              options={['socialMedia', 'webAds', 'printAds', 'editorial'].map(
                (value) => ({
                  value,
                  label: t(`modal.contact.selectValues.usage.${value}`),
                })
              )}
              validate={required}
            />
          )}
          {props.visibleElements.companySize && (
            <Field
              component={Select}
              name="companySize"
              text={t('modal.contact.selectValues.companySize.__selectTitle__')}
              options={['small', 'medium', 'large', 'xlarge'].map((value) => ({
                value,
                label: t(`modal.contact.selectValues.companySize.${value}`),
              }))}
              validate={required}
            />
          )}
          {props.visibleElements.message && (
            <Field
              component={Textarea}
              placeholder={t(
                `modal.contact.${props.formVersion}.placeholderMessage`,
                {
                  photoId: props.attachedData && props.attachedData.photoId,
                }
              )}
              name="message"
              rows={3}
              validate={required}
            />
          )}
          {props.visibleElements.bottomLine && (
            <T component="p" className="modal_p typo-topMarginBoost-small">
              {`modal.contact.${props.formVersion}.bottomLine`}
            </T>
          )}
        </div>
        <div className={props.buttonBoxClassName}>
          <ThemeProvider>
            <Button fullWidth disabled={props.submitPending} type="submit">
              {t('modal.contact.submit.label')}
            </Button>
          </ThemeProvider>
          {props.visibleElements.phoneNumber && [
            <div className="row" key="separator">
              <div className="column small-5 g_form_lineSeparator typo-topMarginBoost-smallStatic" />
              <div className="column small-2">
                <T
                  className={
                    'g_form_orSeparator typo_pMin typo-color-grey3 typo-topMarginBoost-smallStatic'
                  }>
                  authForms.or
                </T>
              </div>
              <div className="column small-5 g_form_lineSeparator typo-topMarginBoost-smallStatic" />
            </div>,
            <Flex key="phone" mt={3} justifyContent="center">
              <Box mr={2}>
                <Support size={24} />
              </Box>
              <Text>{getPhoneNumberByCountryIso(props.countryIso)}</Text>
            </Flex>,
          ]}
        </div>
      </form>
    </ThemeProvider>
  );
}

export default reduxForm({
  form: 'contact',
})(ContactFormForm);
