/* @flow */
import T from '../general/translate.jsx';

function LegacySuccessModal(props: {
  className?: string,
  close: CloseModal,
  translationKeys: {
    message: string,
    subline?: string,
    close: string,
    closeHref?: string,
  },
}) {
  return (
    <div className={props.className}>
      <div className="modal_content">
        <T className="modal_h2" component="h2">
          {props.translationKeys.message}
        </T>
        {props.translationKeys.subline && (
          <T className="modal_p" component="p">
            {props.translationKeys.subline}
          </T>
        )}
      </div>
      <div className="modal_buttonBox modal_buttonBox-seamless">
        <T
          component="button"
          onClick={
            props.translationKeys.closeHref
              ? () =>
                  window.open(props.translationKeys.closeHref, '_blank') &&
                  props.close()
              : props.close
          }
          className="g_btn g_btn-aqua">
          {props.translationKeys.close}
        </T>
      </div>
    </div>
  );
}

export default LegacySuccessModal;
